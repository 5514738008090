import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog38/image1.png"
import image2 from "../../images/blog38/image2.jpg"
import image3 from "../../images/blog38/image3.png"
import image4 from "../../images/blog38/image4.png"










export default function ACLInjuriesPartOne(){
    return(
        <BlogPostOld
            src={image4}
            title={"ACL Injuries Part 1: We Have Been Trending in the Wrong Direction"}
            summary={"We want to share what we think puts an athlete in the best position to come back safely, with as little risk as possible for re-injury. Based on the stats you will want to do whatever it takes to put the odds in your favor, trust me."}
            date={"Oct. 23, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <>
                <p>
                    We want to share what we think puts an athlete in the best position to come
                    back safely, with as little risk as possible for re-injury. Based on the
                    stats you will want to do whatever it takes to put the odds in your favor,
                    trust me.
                </p>
                <p>
                    We have noticed more and more younger athletes experiencing ACL injuries,
                    especially being in the area we are located in San Diego. The conditions are
                    ripe for a high exposure to competition given the beautiful weather year
                    round and the ability for most families to afford to participate in private
                    organized competitions and private skills sessions.&nbsp;
                </p>
                <p>
                    It is a perfect storm for a whole lot of sports for kids. Though this sounds
                    great, it greatly increases the risk for injuries. Afterall, the more you
                    play the higher the risk. This article is for those that have experienced or
                    know someone that has experienced an ACL injury while playing their
                    respective sport.&nbsp;
                </p>
                <p>
                    This is something we feel extremely passionate about because despite all of
                    the technological progress that has been made in medicine, this injury has
                    become MORE common and by a lot. It is not just our observation in our area
                    either, from 1994 to 2006 overall rates of ACL tears have increased 37%, ACL
                    tears in females have increased 307% and ACL injuries in ages 15 or younger
                    have increased 924% (1). Not only have the numbers jumped, but the
                    population that is struggling with this injury the most are young active
                    children. (2)
                </p>
                <p>
                    Another study from looking at athletes from 2002 to 2014 illustrates how
                    much younger female athletes are being affected by ACL tears and subsequent
                    surgical reconstruction (3) (figure below).&nbsp;
                </p>
                <img src={image} />
                <p>
                    Such a steep rise in youth female athletes could be explained by the
                    increased rates of sports participation of that demographic. (4) However
                    when studying college age athletes, females are 3.5 times more likely to
                    tear their ACLs when playing basketball and 2.7 times more likely when
                    playing soccer when compared to college male athletes. (4)
                </p>
                <p>
                    We are not alone here in the States as Australia has found similar outcomes
                    when studying a population of over 200,000 ACL cases from 1998 to 2018.They
                    found a significant rise in annual ACL tears with subsequent reconstructions
                    with the highest rates being in females aged 5-14 years old. (5) This
                    article was published in 2022 and seems to be the most up to date article
                    (that I can find) in regards to trends in ACL tear incidents.
                </p>
                <p>
                    Not only are ACL injuries and subsequent repairs on a steep rise in youth
                    sports (especially in female sports like soccer), the likelihood of
                    re-injury when coming back to play is astounding. One in five athletes
                    experience a reinjury upon returning to high-risk sports after an ACL
                    reconstruction (6). This number is way too high and makes me
                    uncomfortable.&nbsp;
                </p>
                <p>
                    As you can see we are trending in the wrong direction. There are far too
                    many ACL injuries occurring and the rate of re-injury is far too high.&nbsp;
                </p>
                <p>How can we do better?</p>
                <p>
                    From my perspective, I believe we can first do a better job decreasing the
                    risk of reinjury, starting even before the ACL reconstruction surgery
                    happens. The next part of this blog will discuss what we think is the first
                    priority when an ACL injury does occur and the sooner you do it the better.
                </p>
                <p>References:</p>
                <ol>
                    <li>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4555588/"
                            target="_blank"
                            rel="noopener"
                        >
                            Buller LT, Best MJ, Baraga MG, Kaplan LD. Trends in Anterior Cruciate
                            Ligament Reconstruction in the United States. Orthop J Sports Med. 2014
                            Dec 26;3(1):2325967114563664. doi: 10.1177/2325967114563664. PMID:
                            26535368; PMCID: PMC4555588.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://pubmed.ncbi.nlm.nih.gov/25985368/"
                            target="_blank"
                            rel="noopener"
                        >
                            Werner BC, Yang S, Looney AM, Gwath is mey FW Jr. Trends in Pediatric
                            and Adolescent Anterior Cruciate Ligament Injury and Reconstruction. J
                            Pediatr Orthop. 2016 Jul-Aug;36(5):447-52. doi:
                            10.1097/BPO.0000000000000482. PMID: 25985368.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6583877/"
                            target="_blank"
                            rel="noopener"
                        >
                            Herzog MM, Marshall SW, Lund JL, Pate V, Mack CD, Spang JT. Incidence of
                            Anterior Cruciate Ligament Reconstruction Among Adolescent Females in
                            the United States, 2002 Through 2014. JAMA Pediatr. 2017 Aug
                            1;171(8):808-810. doi: 10.1001/jamapediatrics.2017.0740. PMID: 28604937;
                            PMCID: PMC6583877.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4805849/"
                            target="_blank"
                            rel="noopener"
                        >
                            The female ACL: Why is it more prone to injury? J Orthop. 2016 Mar
                            24;13(2):A1-4. doi: 10.1016/S0972-978X(16)00023-4. PMID: 27053841;
                            PMCID: PMC4805849.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://pubmed.ncbi.nlm.nih.gov/35345847/"
                            target="_blank"
                            rel="noopener"
                        >
                            Maniar N, Verhagen E, Bryant AL, Opar DA. Trends in Australian knee
                            injury rates: An epidemiological analysis of 228,344 knee injuries over
                            20 years. Lancet Reg Health West Pac. 2022 Mar 22;21:100409. doi:
                            10.1016/j.lanwpc.2022.100409. PMID: 35345847; PMCID: PMC8956823.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7785893/#bibr18-1941738120912846"
                            target="_blank"
                            rel="noopener"
                        >
                            Barber-Westin S, Noyes FR. One in 5 Athletes Sustain Reinjury Upon
                            Return to High-Risk Sports After ACL Reconstruction: A Systematic Review
                            in 1239 Athletes Younger Than 20 Years. Sports Health. 2020
                            Nov/Dec;12(6):587-597. doi: 10.1177/1941738120912846. Epub 2020 May 6.
                            PMID: 32374646; PMCID: PMC7785893.
                        </a>
                    </li>
                </ol>
            </>



        </BlogPostOld>

    )
}